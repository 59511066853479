import Cookie from 'js-cookie'

export async function logout(history: any, before: () => any) {
  before()
  Cookie.remove('authorization')
  await new Promise(res => setTimeout(res, 500))
  const params = new URLSearchParams(window.location.search)
  if (!window.location.search || !params.get('redirectUrl')) {
    return history.push('/login')
  }
  return window.location.href = decodeURIComponent(params.get('redirectUrl')!)
}