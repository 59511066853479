import { Button } from 'antd'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { CLIENT_ID_WEB, OTORISASI_URL } from '../helpers/Constant'
import { H600 } from './LogoOtorisasi'

interface Props {
  email: string,
  startCounting: boolean,
  onCounterFinished: () => any,
  onFinish?: () => any,
  onError?: (error: string) => any
}

const EmailVerification: React.FC<Props> = ({ email, startCounting, onCounterFinished, onFinish, onError }) => {
  const [waitingTime, setWaitingTime] = useState<number>(0)

  useEffect(() => {
    if (startCounting) {
      setWaitingTime(30)
      onCounterFinished()
    }
  }, [startCounting, onCounterFinished])

  useEffect(() => {
    const timer = setTimeout(() => {
      setWaitingTime(waitingTime - 1)
    }, 1000)
    if (waitingTime <= 0) {
      clearTimeout(timer)
    }
    return () => clearTimeout(timer)
  }, [waitingTime])

  const resend = async () => {
    try {
      await Axios.post(`${OTORISASI_URL}/api/v1/auth/resendVerification`, {
        email,
        clientId: CLIENT_ID_WEB
      }, { withCredentials: true })
      setWaitingTime(30)
      return onFinish?.()
    } catch (error) {
      return onError?.(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  return (
    <>
      <H600 style={{ marginBottom: 4 }}>Check your Email</H600>
      <p style={{ color: '#8993A4', textAlign: 'center' }}>Please check your email <strong>({email})</strong> and click the link provided to confirm your email.</p>
      <p style={{ textAlign: 'center' }}>
        <Button style={waitingTime === 0? { background: '#1256DF' } : {}} disabled={waitingTime > 0} type="primary" onClick={resend}>Resend{waitingTime > 0 ? ` in ${waitingTime}s...` : ''}</Button>
      </p>
      <p style={{ color: '#8993A4', textAlign: 'center' }}>
        Didn't receive the email? Kindly check your spam/junk folder or click Resend button{waitingTime > 0 ? ` after ${waitingTime} seconds` : ''}.
      </p>
    </>
  )
}

export default EmailVerification