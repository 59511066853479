import crypt from 'crypto-js'
import { CLIENT_ID_WEB } from './Constant'

export function encrypt(text: string, secret: string = CLIENT_ID_WEB): string {
  return crypt.AES.encrypt(Buffer.from(text).toString('base64'), secret).toString()
}

export function decrypt(cipherText: string, secret: string = CLIENT_ID_WEB): string | null {
  const value = crypt.AES.decrypt(cipherText, secret)
  if (!value) return null
  return Buffer.from(value.toString(crypt.enc.Utf8), 'base64').toString('utf-8')
}