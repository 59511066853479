import { LockOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Layout, message, Row } from 'antd'
import Axios from 'axios'
import Cookie from 'js-cookie'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OTORISASI_URL } from '../../../helpers/Constant'
import { routingFlow } from '../../../helpers/RoutingFlow'

const TwoFactorAuthVerify: React.FC = () => {
  const history = useHistory()
  const [code, setCode] = useState<string>()

  const verify = async () => {
    try {
      const { data } = await Axios.post(`${OTORISASI_URL}/api/v1/auth/verify2fa`, { code }, { withCredentials: true })
      if (data.isValid) {
        Cookie.set('authorized', 'true')
        await new Promise(res => setTimeout(res, 500))
        return await routingFlow(history, window.location.search ? new URLSearchParams(window.location.search) : null)
      }
      return message.error('Wrong code')
    } catch (error) {
      console.error(error)
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 6, offset: 9 }} md={{ span: 12, offset: 6 }}>
          <Card title="2FA Verification">
            <Form onFinish={verify}>

              <Form.Item
                name="code"
                rules={[{ required: true, message: 'Code cannot be blank' }]}>
                <Input autoComplete="off" placeholder="Code" prefix={<LockOutlined />} value={code} onChange={e => setCode(e.target.value)} />
              </Form.Item>

              <Form.Item>
                <Button style={{ float: 'right' }} type="primary" htmlType="submit">Verify</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default TwoFactorAuthVerify