import React from 'react'
import { Link } from 'react-router-dom'
import { useThemeSwitcher } from 'react-css-theme-switcher'

const Products = () => {
  const { currentTheme } = useThemeSwitcher()
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', padding: '0 20px' }}>
        <p style={{ textAlign: 'center', width: 'max-content', paddingBottom: 4, marginBottom: 0, borderBottom: '0.5px solid #97A0AF' }}>
          <img alt="logo" src={`/product-logo/with-text/fondasi-${currentTheme === 'dark' ? 'dark' : 'light'}.svg`} style={{ width: '89px', padding: '0 4px', maxWidth: '100%' }} />
          <img alt="logo" src={`/product-logo/with-text/implikasi-${currentTheme === 'dark' ? 'dark' : 'light'}.svg`} style={{ width: '96px', padding: '0 4px', maxWidth: '100%' }} />
          <img alt="logo" src={`/product-logo/with-text/klasifikasi-${currentTheme === 'dark' ? 'dark' : 'light'}.svg`} style={{ width: '103px', padding: '0 4px', maxWidth: '100%' }} />
          <img alt="logo" src={`/product-logo/with-text/otomatis-${currentTheme === 'dark' ? 'dark' : 'light'}.svg`} style={{ width: '95px', padding: '0 4px', maxWidth: '100%' }} />
          <img alt="logo" src={`/product-logo/with-text/segmentasi-${currentTheme === 'dark' ? 'dark' : 'light'}.svg`} style={{ width: '116px', padding: '0 4px', maxWidth: '100%' }} />
          <img alt="logo" src={`/product-logo/with-text/verifikasi-${currentTheme === 'dark' ? 'dark' : 'light'}.svg`} style={{ width: '96px', padding: '0 4px', maxWidth: '100%' }} />
        </p>
      </div>
      <p style={{ textAlign: 'center', color: '#8993A4'}}>All in one account, connected by<Link to="/"><img alt="logo" src={`/product-logo/Logotype/otorisasi - logo${currentTheme === 'dark' ? ' (inv)' : ''}.png`} style={{ width: '134px', maxWidth: '100%' }} /></Link></p>
    </>
  )
}

export default Products