import styled from 'styled-components'

const LogoOtorisasi = styled.img`
  width: 63px;
  max-width: 100%;
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);
`
export default LogoOtorisasi

export const H600 = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #5E6C84;
  text-align: center;
  margin-top: 20px;
`