import { Button, Card, Col, Layout, Row, Skeleton, Space, Typography } from 'antd'
import Axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { OTORISASI_URL } from '../../helpers/Constant'

const Authorize: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const [client, setClient] = useState<any>()
  const [descriptions, setDescriptions] = useState<string[]>()

  const authorizeClient = useCallback(async () => {
    const { installationId, clientId, state } = location.state as any
    const { data } = await Axios.post(`${OTORISASI_URL}/api/v1/auth/authorize`, {
      installationId, clientId, state }, { withCredentials: true })
    return window.location.href = data.url
  }, [location])

  useEffect(() => {
    (async () => {
      if (!location?.state) {
        return history.replace('/account')
      }
      const { ref, installationId, clientId } = location.state as any
      if (!ref || (!installationId && !clientId)) {
        return history.replace('/account')
      }

      try {
        const { data } = await Axios.get(`${OTORISASI_URL}/api/v1/user/me/clients`, {
          withCredentials: true,
          params: installationId ? { installationId } : { clientId }
        })
        if (data?.clients?.length) {
          return authorizeClient()
        }
      } catch (error) {
        return history.replace('/login')
      }

      const { data } = await Axios.get(`${OTORISASI_URL}/api/v1/clients`, {
        withCredentials: true,
        params: installationId ? { installationId } : { clientId }
      })
      if (!data?.clients.length) {
        return history.replace('/account')
      }
      setClient(data.clients[0])
      const desc: string[] = ['Retrieve your basic info such as name, email, and username.']
      for (const scope of data.clients[0].scopes) {
        if (scope === 'all') {
          desc.push('Get your role and permissions.')
        }
      }
      setDescriptions(desc)
    })()
  }, [history, location, authorizeClient])

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 10, offset: 7 }} md={{ span: 18, offset: 3 }}>
          <Card>
            <Typography.Title level={4} type="secondary">
              Authorize <Typography.Text>{client?.name}</Typography.Text> to connect with your account?
            </Typography.Title>
            <p style={{ textAlign: 'center', padding: '20px 0' }}>
              { client?.iconUrl ? <img alt="icon" src={client?.iconUrl} style={{ width: '72px', maxWidth: '100%' }} /> : <Skeleton.Image /> }
            </p>
            <Card type="inner">
              <p>You agree that {client?.name} will be able to:</p>
              <ul>
                { descriptions?.map((desc, i) => <li key={i}>{desc}</li>) }
              </ul>
            </Card>
            <p style={{ textAlign: 'center', marginTop: '20px' }}>
              <Space>
                <Link to="/logout"><Button>Cancel</Button></Link>
                <Button type="primary" onClick={authorizeClient}>Agree</Button>
              </Space>
            </p>
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default Authorize