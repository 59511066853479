import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Layout, Row } from 'antd'
import Cookie from 'js-cookie'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { logout } from '../../helpers/Logout'

const Logout: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => logout(history, () => {
      Cookie.remove('authorization')
      Cookie.remove('authorized')
    }), 500)
  }, [history])

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 6, offset: 9 }} md={{ span: 12, offset: 6 }}>
          <Card title="Logging out">
            Please wait... <LoadingOutlined />
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default Logout