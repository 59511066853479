import { MailOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Layout, message, Row } from 'antd'
import Axios from 'axios'
import React, { useState } from 'react'
import Recaptcha from 'react-google-invisible-recaptcha'
import { Link } from 'react-router-dom'
import { CLIENT_ID_WEB, OTORISASI_URL, SITE_KEY_RECAPTCHA } from '../../helpers/Constant'
import LogoOtorisasi, { H600 } from '../../components/LogoOtorisasi'
import Products from '../../components/Products'

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>()
  const [captcha, setCaptcha] = useState<any>()

  const send = async () => {
    try {
      const token = await captcha?.execute()
      const { data } = await Axios.post(`${OTORISASI_URL}/api/v1/google/recaptcha`, { token }, { withCredentials: true })
      if (!data?.isValid) throw new Error()
    } catch (error) {
      return message.error('Robot detected!')
    }

    try {
      await Axios.post(`${OTORISASI_URL}/api/v1/auth/forgotPassword`, {
        email, clientId: CLIENT_ID_WEB
      }, { withCredentials: true })
      return message.info('Please check your email and follow the link')
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  return (
    <Layout.Content>
      <Row>
      <Col span={24} lg={{ span: 8, offset: 8 }} md={{ span: 12, offset: 6 }}>
      <LogoOtorisasi alt="logo" src="/product-logo/Logogram/Otorisasi.svg" />
          <Card>
            <Form onFinish={send}>
              <H600>Forgot Password</H600>
              <p style={{ textAlign: 'center', marginTop: '8px', color: '#8993A4' }}>We’ll send you a confirmation link, kindly put your email address below</p>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Email cannot be blank' }]}>
                <Input type="email" placeholder="Your Email" prefix={<MailOutlined />} value={email} onChange={e => setEmail(e.target.value)} />
              </Form.Item>

              <Recaptcha
                ref={(ref: any) => setCaptcha(ref)}
                sitekey={SITE_KEY_RECAPTCHA}
                badge="bottomleft" />

              <Form.Item style={{ textAlign: 'center' }}>
                <Button style={{ width: '100%', background: '#1256DF' }} type="primary" htmlType="submit">Send</Button>
                <Link style={{ color: '#1256DF' }} to="/login">Oh shit, I remember now!</Link>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <br />
      <Products />
    </Layout.Content>
  )
}

export default ForgotPassword