import { LockOutlined } from '@ant-design/icons'
import { Button, Card, Col, Collapse, Form, Input, Layout, message, Row, Skeleton, Typography } from 'antd'
import Axios from 'axios'
import qrcode from 'qrcode'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OTORISASI_URL } from '../../../helpers/Constant'

const TwoFactorAuthSetup: React.FC = () => {
  const history = useHistory()
  const [tfa, setTfa] = useState<any>()
  const [code, setCode] = useState<string>()

  useEffect(() => {
    Axios.post(`${OTORISASI_URL}/api/v1/auth/setup2fa`, {}, { withCredentials: true })
    .then(({ data }) => {
      qrcode.toDataURL(data.url, ((err, imageUrl) => {
        if (err) {
          return message.error('Something error')
        }
        setTfa({ ...data, url: imageUrl })
      }))
    })
    .catch(error => message.error(error?.response?.data?.error || 'Something error, please reload this page'))
  }, [])

  const activate = async () => {
    try {
      const { data } = await Axios.post(`${OTORISASI_URL}/api/v1/auth/activate2fa`, { code }, { withCredentials: true })
      if (!data.isValid) {
        return message.error('Wrong code')
      }
      message.success('Success! Redirecting you to profile page...')
      return setTimeout(() => history.push('/account'), 1500)
    } catch (error) {
      return message.error('Wrong code')
    }
  }

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 10, offset: 7 }} md={{ span: 18, offset: 3 }}>
          <Card title="Enable Two-factor Authentication">
            <div>
              <small>Step 1</small>
              <Typography.Title level={5}>Get the App</Typography.Title>
              <Typography.Paragraph>
                Download and install the <a href="https://m.google.com/authenticator" target="_blank" rel="noopener noreferrer">Google Authenticator</a>, <a href="http://guide.duosecurity.com/third-party-accounts" target="_blank" rel="noopener noreferrer">Duo Mobile</a>, <a href="https://www.authy.com/" target="_blank" rel="noopener noreferrer">Authy</a>, or <a href=" https://www.windowsphone.com/en-us/store/app/authenticator/e7994dbc-2336-4950-91ba-ca22d653759b">Windows Phone Authenticator</a> app for your phone or tablet.
              </Typography.Paragraph>
            </div>
            <div>
              <small>Step 2</small>
              <Typography.Title level={5}>Scan this Barcode</Typography.Title>
              <Typography.Paragraph>Open the authentication app and:</Typography.Paragraph>
              <ul>
                <li>Tap the "+" icon in the top-right of the app</li>
                <li>Scan the image below, using your phone's camera</li>
              </ul>
              <Typography.Paragraph style={{ textAlign: 'center' }}>
                { tfa?.url ? <img src={tfa?.url} alt="qrcode" style={{}} /> : <Skeleton.Image /> }
              </Typography.Paragraph>
              <Collapse>
                <Collapse.Panel key="1" header="Can't scan this barcode?">
                  <Typography.Paragraph>
                    Instead of scanning, use your authentication app's "Manual entry" or equivalent option and provide the following time-based key. (Lower-case letters will work, too.)
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ textAlign: 'center' }}><code>{tfa?.key}</code></Typography.Paragraph>
                  <Typography.Paragraph>
                    Your app will then generate a 6-digit verification code, which you use below.
                  </Typography.Paragraph>
                </Collapse.Panel>
              </Collapse>
              <br />
            </div>
            <div>
              <small>Step 3</small>
              <Typography.Title level={5}>Enter Verification Code</Typography.Title>
              <Typography.Paragraph>
                Once the barcode above is scanned, enter the 6-digit verification code generated by the app.
              </Typography.Paragraph>
              <Form layout="inline" onFinish={activate}>
                <Form.Item
                  name="code"
                  rules={[{ required: true, message: 'Code cannot be blank' }]}>
                  <Input placeholder="Code" prefix={<LockOutlined />} value={code} onChange={e => setCode(e.target.value)} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">Verify and Activate</Button>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default TwoFactorAuthSetup