import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Layout, message, Row } from 'antd'
import { PasswordInput } from 'antd-password-input-strength'
import Axios from 'axios'
import React, { useState } from 'react'
import Recaptcha from 'react-google-invisible-recaptcha'
import EmailVerification from '../../components/EmailVerification'
import { CLIENT_ID_WEB, OTORISASI_URL, SITE_KEY_RECAPTCHA } from '../../helpers/Constant'
import { encrypt } from '../../helpers/Crypto'
import Products from '../../components/Products'
import LogoOtorisasi, { H600 } from '../../components/LogoOtorisasi'
import { Link } from 'react-router-dom'

const Signup: React.FC = () => {
  const [username, setUsername] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [verify, setVerify] = useState<boolean>()
  const [startCounter, setStartCounter] = useState<boolean>()
  const [captcha, setCaptcha] = useState<any>()

  const signup = async () => {
    try {
      const token = await captcha?.execute()
      const { data } = await Axios.post(`${OTORISASI_URL}/api/v1/google/recaptcha`, { token }, { withCredentials: true })
      if (!data?.isValid) throw new Error()
    } catch (error) {
      return message.error('Robot detected!')
    }
    try {
      await Axios.post(`${OTORISASI_URL}/api/v1/auth/register`, {
        user: {
          name: username,
          username,
          password: encrypt(password!),
          email,
          clientId: CLIENT_ID_WEB
        }
      }, { withCredentials: true })
      setVerify(true)
      setStartCounter(true)
      return message.success('Success!')
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  return (
    <Layout.Content>
      <Row>
      <Col span={24} lg={{ span: 8, offset: 8 }} md={{ span: 12, offset: 6 }}>
          <LogoOtorisasi alt="logo" src="/product-logo/Logogram/Otorisasi.svg" />
          <Card>
            { verify ? (
              <EmailVerification email={email!} onError={err => message.error(err)} startCounting={!!startCounter} onCounterFinished={() => setStartCounter(false)} />
            ) : (
              <Form onFinish={signup}>
                <H600>Sign Up with Your Otorisasi Account</H600>
                <p style={{ textAlign: 'center', marginTop: '8px', color: '#8993A4' }}>Already have an account. <Link to="/login" style={{ color: '#1256DF' }}>Sign me in!</Link></p>
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Username cannot be blank' }]}>
                  <Input placeholder="Your Username" prefix={<UserOutlined />} value={username} onChange={e => setUsername(e.target.value)} />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Email cannot be blank' }]}>
                  <Input type="email" placeholder="Your Email" prefix={<MailOutlined />} value={email} onChange={e => setEmail(e.target.value)} />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Password cannot be blank' }]}>
                  <PasswordInput onChange={e => setPassword(e.target.value)} inputProps={{
                    prefix: <LockOutlined />,
                    placeholder: 'Your Password',
                    value: password
                  }} />
                </Form.Item>

                <Recaptcha
                  ref={(ref: any) => setCaptcha(ref)}
                  sitekey={SITE_KEY_RECAPTCHA}
                  badge="bottomleft" />

                <Form.Item>
                  <Button style={{ width: '100%', background: '#1256DF' }} type="primary" htmlType="submit">Sign Up</Button>
                </Form.Item>
              </Form>
            ) }
          </Card>
        </Col>
      </Row>
      <br />
      <Products />
    </Layout.Content>
  )
}

export default Signup