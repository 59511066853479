import { ClockCircleOutlined, IdcardOutlined, LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Layout, message, Row, Select, Skeleton } from 'antd'
import { PasswordInput } from 'antd-password-input-strength'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import EmailVerification from '../../components/EmailVerification'
import { OTORISASI_URL } from '../../helpers/Constant'
import { encrypt } from '../../helpers/Crypto'
import { logout } from '../../helpers/Logout'

const EditProfile: React.FC = () => {
  const history = useHistory()
  const [formProfile] = Form.useForm()
  const [formEmail] = Form.useForm()
  const [formPassword] = Form.useForm()
  const [user, setUser] = useState<any>()
  const [email, setEmail] = useState<string>()
  const [needEmailVerification, setNeedEmailVerification] = useState<boolean>()
  const [startCounter, setStartCounter] = useState<boolean>()
  const [oldPassword, setOldPassword] = useState<string>()
  const [newPassword, setNewPassword] = useState<string>()
  const [timezones, setTimezones] = useState<string[]>()

  useEffect(() => {
    Axios.get('https://worldtimeapi.org/api/timezone').then(({ data }) => setTimezones(data))
    Axios.get(`${OTORISASI_URL}/api/v1/user/me`, { withCredentials: true })
    .then(({ data }) => {
      const user = {
        name: data?.user?.name,
        username: data?.user?.username,
        phone: data?.user?.phone,
        timezone: data?.user?.timezone
      }
      setUser(user)
      formProfile.setFieldsValue(user)
      setEmail(data?.user?.email)
      formEmail.setFieldsValue({ email: data?.user?.email })
    })
    .catch(() => history.replace('/login'))
  }, [history, formProfile, formEmail])

  const updateBasicProfile = async () => {
    try {
      await Axios.patch(`${OTORISASI_URL}/api/v1/user/me`, { user }, { withCredentials: true })
      message.success('Profile updated')
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  const updateEmail = async () => {
    try {
      await Axios.post(`${OTORISASI_URL}/api/v1/auth/changeEmail`, { email }, { withCredentials: true })
      message.success('Email updated')
      setNeedEmailVerification(true)
      setStartCounter(true)
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  const updatePassword = async () => {
    try {
      await Axios.post(`${OTORISASI_URL}/api/v1/auth/changePassword`, {
        oldPassword: encrypt(oldPassword!),
        newPassword: encrypt(newPassword!)
      }, { withCredentials: true })
      formPassword.setFieldsValue({
        oldPassword: '',
        newPassword: '',
        confirm: ''
      })
      message.success('Password changed')
      setTimeout(() => logout(history, () => {}), 1500)
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 10, offset: 7 }} md={{ span: 18, offset: 3 }}>
          <Card>
            { !user || !email ? <Skeleton /> : (
              <>
                <Card type="inner" title="Basic Profile">
                  <Form onFinish={updateBasicProfile} form={formProfile} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>

                    <Form.Item
                      name="username"
                      label="Username"
                      rules={[{ required: true, message: 'Username cannot be blank' }]}>
                      <Input prefix={<UserOutlined />} value={user?.username} onChange={e => setUser({ ...user, username: e.target.value })} />
                    </Form.Item>

                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[{ required: true, message: 'Name cannot be blank' }]}>
                      <Input prefix={<IdcardOutlined />} value={user?.name} onChange={e => setUser({ ...user, name: e.target.value })} />
                    </Form.Item>

                    <Form.Item
                      name="phone"
                      label="Phone"
                      rules={[{ message: 'Phone cannot be blank' }]}>
                      <Input type="tel" prefix={<PhoneOutlined />} value={user?.phone} onChange={e => setUser({ ...user, phone: e.target.value })} />
                    </Form.Item>

                    <Form.Item
                      name="timezone"
                      label="Timezone"
                      rules={[{ required: true, message: 'Timezone cannot be blank' }]}>
                      <Select onChange={value => setUser({ ...user, timezone: value })}>
                        { timezones?.map((tz, i) => <Select.Option key={i} value={tz}><ClockCircleOutlined /> {tz}</Select.Option>) }
                      </Select>
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button style={{ float: 'right' }} type="primary" htmlType="submit">Update Profile</Button>
                    </Form.Item>
                  </Form>
                </Card>

                <br />
                <Card type="inner" title={needEmailVerification ? 'Need Verification' : 'Primary Email'}>
                  { needEmailVerification ? <EmailVerification email={email!} onError={err => message.error(err)} startCounting={!!startCounter} onCounterFinished={() => setStartCounter(false)} /> : (
                    <Form onFinish={updateEmail} form={formEmail} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>

                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Email cannot be blank' }]}>
                        <Input type="email" prefix={<MailOutlined />} value={email} onChange={e => setEmail(e.target.value)} />
                      </Form.Item>

                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="primary" style={{ float: 'right' }} htmlType="submit">Update Primary Email</Button>
                      </Form.Item>
                    </Form>
                  ) }
                </Card>

                <br />
                <Card type="inner" title="Password">
                  <Form onFinish={updatePassword} form={formPassword} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>

                    <Form.Item
                      name="oldPassword"
                      label="Old Password"
                      rules={[{ required: true, message: 'Old password cannot be blank' }]}>
                      <Input.Password prefix={<LockOutlined />} value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                      name="newPassword"
                      label="New Password"
                      rules={[{ required: true, message: 'New password cannot be blank' }]}>
                      <PasswordInput onChange={e => setNewPassword(e.target.value)} inputProps={{
                        prefix: <LockOutlined />,
                        value: newPassword
                      }} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button style={{ float: 'right' }} type="primary" htmlType="submit">Change Password</Button>
                    </Form.Item>
                  </Form>
                </Card>
              </>
            ) }
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default EditProfile