import { Button, Col, Layout, Result, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage: React.FC = () => {

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 6, offset: 9 }} md={{ span: 12, offset: 6 }}>
          <Result
            icon={<img style={{ width: '100%', margin: '0 auto' }}
            src="./giphy.gif" alt="spongebob" />}
            title="404"
            subTitle="Sorry, the page you requested for is doesn't exist. Here SpongeBob will tell you the right path."
            extra={<Link to="/"><Button type="primary">I can't hear him. Just take me home!</Button></Link>} />
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default NotFoundPage