import { LockOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Layout, message, Row } from 'antd'
import { PasswordInput } from 'antd-password-input-strength'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CLIENT_ID_WEB, OTORISASI_URL } from '../../helpers/Constant'
import { encrypt } from '../../helpers/Crypto'

const ResetPassword: React.FC = () => {
  const history = useHistory()
  const [password, setPassword] = useState<string>()

  useEffect(() => {
    if (!window.location.search) {
      return history.push('/login')
    }
    const params = new URLSearchParams(window.location.search)
    if (!params.get('email') || !params.get('code')) {
      return history.push('/login')
    }
  }, [history])

  const send = async () => {
    try {
      const params = new URLSearchParams(window.location.search)
      await Axios.post(`${OTORISASI_URL}/api/v1/auth/resetPassword`, {
        email: params.get('email'),
        code: params.get('code'),
        password: encrypt(password!),
        clientId: CLIENT_ID_WEB
      }, { withCredentials: true })
      message.success('Success! Please login again')
      setTimeout(() => history.push('/login'), 1500)
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 6, offset: 9 }} md={{ span: 12, offset: 6 }}>
          <Card title="Forgot Password">
            <Form onFinish={send}>

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Password cannot be blank' }]}>
                <PasswordInput onChange={e => setPassword(e.target.value)} inputProps={{
                  prefix: <LockOutlined />,
                  placeholder: 'Password',
                  value: password
                }} />
              </Form.Item>

              <Form.Item
                name="confirm"
                dependencies={['password']}
                rules={[
                  { required: true, message: 'Password cannot be blank' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return new Promise((resolve, reject) => {
                        if (!value || getFieldValue('password') === value) {
                          return resolve()
                        }
                        return reject('The two passwords that you entered do not match')
                      })
                    }
                  })
                ]}>
                <Input.Password placeholder="New Password Confirmation" prefix={<LockOutlined />} />
              </Form.Item>

              <Form.Item>
                <Button style={{ float: 'right' }} type="primary" htmlType="submit">Send</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default ResetPassword