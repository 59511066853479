import Axios from 'axios'
import Cookie from 'js-cookie'
import queryString from 'query-string'
import { OTORISASI_URL } from './Constant'

export async function routingFlow(history: any, params?: any, user?: any): Promise<any> {
  if (Cookie.get('authorized') !== 'true' && Cookie.get('authorization') && user?.isEnabled2fa) {
    return history.push(queryString.stringifyUrl({
      url: '/2fa/verify',
      query: params ? Object.fromEntries(params) : {}
    }))
  } else if (params?.get('installationId') || params?.get('clientId')) {
    const { data } = await Axios.get(`${OTORISASI_URL}/api/v1/clients`, {
      withCredentials: true,
      params: params?.get('installationId') ? {
        installationId: params.get('installationId')
      } : {
        clientId: params?.get('clientId')
      },
    })
    if (data?.clients?.length) {
      const authorize = async () => {
        const { data } = await Axios.post(`${OTORISASI_URL}/api/v1/auth/authorize`, {
          ...params?.get('installationId') ? {
            installationId: params.get('installationId')
          } : {
            clientId: params.get('clientId')
          },
          state: params.get('state')
        }, { withCredentials: true })
        return window.location.href = data.url
      }

      // Bypass authorization page for internal product
      if (!data?.clients[0].isNeedCodeVerification) {
        return await authorize()
      } else {
        const { data } = await Axios.get(`${OTORISASI_URL}/api/v1/user/me/clients`, {
          withCredentials: true,
          params: params?.get('installationId') ? {
            installationId: params.get('installationId')
          } : {
            clientId: params?.get('clientId')
          }
        })
        if (data?.clients?.length) {
          return await authorize()
        }
      }

      // const { data } = await Axios.get(`${OTORISASI_URL}/api/v1/user/me/clients`, {
      //   withCredentials: true,
      //   params: params?.get('installationId') ? {
      //     installationId: params.get('installationId')
      //   } : {
      //     clientId: params?.get('clientId')
      //   }
      // })
      // if (data?.clients?.length) {
      //   return await authorize()
      // }
    }
    return history.push({
      pathname: '/authorize', state: {
        ref: params.get('ref'),
        installationId: params.get('installationId'),
        clientId: params.get('clientId'),
        state: params.get('state')
      }
    })
  } else if (params?.get('redirectUrl')) {
    return window.location.href = queryString.stringifyUrl({
      url: decodeURIComponent(params.get('redirectUrl')),
      query: params.get('state') ? { state: params.get('state') } : {}
    })
  } else {
    return history.push('/account')
  }
}