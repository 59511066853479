import { SafetyCertificateOutlined, CheckOutlined, CloseOutlined, StopOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Layout, message, Row, Table, Typography } from 'antd'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OTORISASI_URL } from '../../helpers/Constant'

const UserManagement: React.FC = () => {
  const PAGE_SIZE = 15
  const history = useHistory()
  const [search, setSearch] = useState<string>()
  const [users, setUsers] = useState<any[]>()
  const [loading, setLoading] = useState<boolean>()
  const [total, setTotal] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<[number[], any[]]>()
  const [tableState, setTableState] = useState<[any, any, any]>()

  useEffect(() => {
    setLoading(true)
    Axios.get(`${OTORISASI_URL}/api/v1/user/me`, { withCredentials: true })
    .then(({ data }) => {
      if (!data.user.isAdmin) {
        return history.replace('/account')
      }
      Axios.get(`${OTORISASI_URL}/api/v1/users`, { withCredentials: true, params: {
        skip: 0,
        take: PAGE_SIZE,
        withDeleted: true
      } })
      .then(({ data }) => {
        setUsers(data.users)
        setTotal(data.length)
        setLoading(false)
      })
      .catch(() => message.error('Something error, please reload this page'))
    })
    .catch(() => history.replace('/login'))
  }, [history])

  const changeHandler = async (pagination: any, filters: any, sorter: any, _?: any, query?: string) => {
    setUsers([])
    setLoading(true)
    const additionalFilter = filters ? (Object.keys(filters) || [])?.filter(field => filters[field]).reduce((res, field) => {
      if (field === 'deletedAt') {
        if (filters[field].includes('true') && filters[field].includes('false')) {
          return res
        }
        if (field === 'deletedAt') {
          return `${res} and "user"."${field}" is ${!filters[field].includes('true') ? 'not' : ''} null`
        }
      }
      return `${res} and "user"."${field}" in (${(filters[field] as string[]).map(val => `'${val}'`).join(', ')})`
    }, 'true') : 'true'

    query = query === undefined ? search : query || ''
    try {
      const { data } = await Axios.post(`${OTORISASI_URL}/api/v1/users`, {
        search: query ? `${additionalFilter} and (user.name like '%${query}%' or user.email like '%${query}%' or user.username like '%${query}%')` : `${additionalFilter} and true`
      }, { withCredentials: true, params: {
        skip: ((pagination?.current || 1) - 1) * PAGE_SIZE,
        take: PAGE_SIZE,
        withDeleted: true,
        ...sorter?.field ?  { orderBy: `"${sorter.field}":${sorter.order === 'ascend' ? 'asc' : 'desc'}` } : {}
      } })
      setTableState([pagination, filters, sorter])
      setUsers(data.users)
      setTotal(data.length)
    } catch (error) {
      message.error(error?.response?.data?.error || 'Something error, please reload your browser')
    }
    setLoading(false)
  }

  const searchUsers = async (val: string) => {
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2], null, val || '')
    setSearch(val)
  }

  const disable = async () => {
    if (!selectedIds?.[0]?.length) {
      return
    }
    setLoading(true)
    for (const i of selectedIds[0]) {
      try {
        await Axios.delete(`${OTORISASI_URL}/api/v1/user/${i}/archive`, { withCredentials: true })
      } catch (error) {
        console.error(error)
        message.error(`Error on update user ID ${i}, please see the console`)
      }
    }
    setSelectedIds([[], []])
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2])
    setLoading(false)
    return message.success('Users disabled (maybe) successfully')
  }

  const enable = async () => {
    if (!selectedIds?.[0]?.length) {
      return
    }
    setLoading(true)
    for (const i of selectedIds[0]) {
      try {
        await Axios.patch(`${OTORISASI_URL}/api/v1/user/${i}/restore`, {}, { withCredentials: true })
      } catch (error) {
        setLoading(false)
        return message.success('Users disabled (maybe) successfully')
      }
    }
    setSelectedIds([[], []])
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2])
    setLoading(false)
    return message.success('Users enabled (maybe) successfully')
  }

  const verify = async () => {
    if (!selectedIds?.[0]?.length) {
      return
    }
    setLoading(true)
    for (const i of selectedIds[0]) {
      try {
        await Axios.patch(`${OTORISASI_URL}/api/v1/user/${i}`, {
          user: {
            isVerified: true,
            emailVerificationToken: null
          }
        }, { withCredentials: true })
      } catch (error) {
        setLoading(false)
        return message.success('Users disabled (maybe) successfully')
      }
    }
    setSelectedIds([[], []])
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2])
    setLoading(false)
    return message.success('Users verified (maybe) successfully')
  }

  const switchUser = async () => {
    if (!selectedIds?.[0]?.length) {
      return
    }
    setLoading(true)
    for (const user of selectedIds[1]) {
      try {
        await Axios.patch(`${OTORISASI_URL}/api/v1/user/${user.id}`, {
          user: {
            isAdmin: !user.isAdmin
          }
        }, { withCredentials: true })
      } catch (error) {
        setLoading(false)
        return message.success('Users disabled (maybe) successfully')
      }
    }
    setSelectedIds([[], []])
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2])
    setLoading(false)
    return message.success('Users switched (maybe) successfully')
  }

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 22, offset: 1 }}>
          <Form>
            <Form.Item name="search" wrapperCol={{ md: { span: 8, offset: 16 } }}>
              <Input.Search
                placeholder="Search by name, email, or username..." onSearch={searchUsers} allowClear />
            </Form.Item>
          </Form>
          <Card title="Users">
            <>
              <Button style={{ margin: '0 7px 15px 0' }} size="small" icon={<StopOutlined />} danger type="primary" disabled={!selectedIds?.[0]?.length || !!selectedIds?.[1]?.find(user => user.deletedAt)} onClick={disable}>Disable</Button>
              <Button style={{ margin: '0 7px 15px 0' }} size="small" icon={<CheckOutlined />} type="primary" disabled={!selectedIds?.[0]?.length || !!selectedIds?.[1]?.find(user => !user.deletedAt)} onClick={enable}>Enable</Button>
              <Button style={{ margin: '0 7px 15px 0' }} size="small" icon={<SafetyCertificateOutlined />} type="primary" disabled={!selectedIds?.[0]?.length || !!selectedIds?.[1]?.find(user => user.isVerified)} onClick={verify}>Verify</Button>
              <Button style={{ margin: '0 7px 15px 0' }} size="small" icon={<UserSwitchOutlined />} type="primary" disabled={!selectedIds?.[0]?.length} onClick={switchUser}>Switch to Superadmin/User</Button>
            </>
            <Typography.Paragraph style={{ float: 'right' }} type="secondary">{total} users</Typography.Paragraph>
            <Table
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  fixed: 'left',
                  width: 70,
                  sorter: (a, b) => a.id - b.id
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                  width: 240,
                  sorter: (a, b) => a.name.localeCompare(b.name)
                },
                {
                  title: 'Email',
                  dataIndex: 'email',
                  key: 'email',
                  width: 240,
                  sorter: (a, b) => a.email.localeCompare(b.email)
                },
                {
                  title: 'Username',
                  dataIndex: 'username',
                  key: 'username',
                  width: 240,
                  sorter: (a, b) => a.username.localeCompare(b.username)
                },
                {
                  title: 'Admin',
                  dataIndex: 'isAdmin',
                  key: 'isAdmin',
                  width: 130,
                  render: isAdmin => <>{isAdmin ? <Typography.Text type="success"><CheckOutlined /></Typography.Text> : <Typography.Text type="danger"><CloseOutlined /></Typography.Text>}</>,
                  filterMultiple: false,
                  filters: [
                    { text: 'Admin', value: true },
                    { text: 'Not Admin', value: false },
                  ]
                },
                {
                  title: 'Verified',
                  dataIndex: 'isVerified',
                  key: 'isVerified',
                  width: 100,
                  render: isVerified => <>{isVerified ? <Typography.Text type="success"><CheckOutlined /></Typography.Text> : <Typography.Text type="danger"><CloseOutlined /></Typography.Text>}</>,
                  filterMultiple: false,
                  filters: [
                    { text: 'Verified', value: true },
                    { text: 'Not verified', value: false },
                  ]
                },
                {
                  title: 'Last Login',
                  dataIndex: 'lastLogin',
                  key: 'lastLogin',
                  width: 240,
                  sorter: (a, b) => (a.lastLogin ? new Date(a.lastLogin).getTime() : Infinity) - (b.lastLogin ? new Date(b.lastLogin).getTime() : Infinity)
                },
                {
                  title: 'Enable 2FA',
                  dataIndex: 'isEnabled2fa',
                  key: 'isEnabled2fa',
                  width: 140,
                  render: isEnabled2fa => <>{isEnabled2fa ? <Typography.Text type="success"><CheckOutlined /></Typography.Text> : <Typography.Text type="danger"><CloseOutlined /></Typography.Text>}</>,
                  filterMultiple: false,
                  filters: [
                    { text: 'Enable', value: true },
                    { text: 'Disable', value: false },
                  ],
                },
                {
                  title: 'Registered At',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  width: 240,
                  sorter: (a, b) => (a.deletedAt ? new Date(a.deletedAt).getTime() : Infinity) - (b.deletedAt ? new Date(b.deletedAt).getTime() : Infinity)
                },
                {
                  title: 'Is Active',
                  dataIndex: 'deletedAt',
                  key: 'deletedAt',
                  width: 120,
                  render: deletedAt => <>{!deletedAt ? <Typography.Text type="success"><CheckOutlined /></Typography.Text> : <Typography.Text type="danger"><CloseOutlined /></Typography.Text>}</>,
                  filterMultiple: false,
                  filters: [
                    { text: 'Active', value: true },
                    { text: 'Inactive', value: false },
                  ]
                },
              ]}
              dataSource={users?.map(user => ({ ...user, key: user.id }))}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedIds?.[0] || [],
                onChange: (selectedRowKeys, data) => setSelectedIds([selectedRowKeys as number[], data])
              }}
              onChange={changeHandler}
              sticky
              scroll={{ x: 1300 }}
              pagination={{
                pageSize: 15,
                total: total
              }}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default UserManagement