import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Col, Divider, Form, Input, Layout, message, Row, Spin } from 'antd'
import Axios from 'axios'
import Cookie from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'
import Recaptcha from 'react-google-invisible-recaptcha'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Products from '../../components/Products'
import { CLIENT_ID_GOOGLE, CLIENT_ID_WEB, OTORISASI_URL, SITE_KEY_RECAPTCHA } from '../../helpers/Constant'
import { encrypt } from '../../helpers/Crypto'
import { routingFlow } from '../../helpers/RoutingFlow'

const Login: React.FC = () => {
  const history = useHistory()
  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [captcha, setCaptcha] = useState<any>()
  const [hideAdditionalLoginOption, setHideAdditionalLoginOption] = useState<boolean>(true)

  const _routingFlow = useCallback(async (user?: any) => {
    setLoading(false)
    await routingFlow(history, window.location.search ? new URLSearchParams(window.location.search) : null, user)
  }, [history])

  useEffect(() => {
    Axios.get(`${OTORISASI_URL}/api/v1/clients`, { params: { clientId: CLIENT_ID_WEB } })
    .then(({ data }) => {
      Axios.get(`${OTORISASI_URL}/api/v1/application/${data.clients[0].applicationId}`)
      .then(({ data }) => {
        setHideAdditionalLoginOption(!data.application.enabledSignup)
      })
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    Axios.get(`${OTORISASI_URL}/api/v1/user/me`, { withCredentials: true })
    .then(({ data }) => _routingFlow(data.user))
    .catch(() => setLoading(false))
  }, [history, _routingFlow])

  const login = async () => {
    if (new Date().getMonth() === 3 && new Date().getDate() === 1 && localStorage.getItem('yeay') !== new Date().getFullYear().toString()) {
      message.error('Error: 418. I\'m a teapot.')
      await new Promise(res => setTimeout(res, 4500))
      message.info('Just kidding. Happy april fool\'s day!')
      await new Promise(res => setTimeout(res, 3500))
      message.info('How was your day? Is the apocalypse already out there?', 4)
      await new Promise(res => setTimeout(res, 4500))
      message.info('Sorry to interupt you. I\'m lonely :(')
      await new Promise(res => setTimeout(res, 3500))
      message.info('OK! Go on. Bye~')
      await new Promise(res => setTimeout(res, 3500))
      localStorage.setItem('yeay', new Date().getFullYear().toString())
    }

    try {
      setLoading(true)

      const token = await captcha?.execute()
      const { data } = await Axios.post(`${OTORISASI_URL}/api/v1/google/recaptcha`, { token }, { withCredentials: true })
      if (!data?.isValid) throw new Error()
    } catch (error) {
      setLoading(false)
      return message.error('Robot detected!')
    }

    let token: any
    try {
      token = await Axios.post(`${OTORISASI_URL}/api/v1/auth/token`, {
        grantType: 'password',
        clientId: CLIENT_ID_WEB,
        username,
        password: encrypt(password!)
      }, { withCredentials: true })
      if (!token.data.auth.need2fa) {
        Cookie.set('authorized', 'true')
      }
    } catch (error) {
      setLoading(false)
      if (new Date().getMonth() === 3 && new Date().getDate() === 1) {
        return message.error('Huh?')
      }
      return message.error('Username/password wrong')
    }

    const params = new URLSearchParams(window.location.search)
    if (!params.get('installationId') && !params.get('clientId') && params.get('redirectUrl')) {
      message.success(`Redirecting to ${params.get('ref')}...`)
    }

    _routingFlow({ isEnabled2fa: token.data.auth.need2fa })
  }

  const googleLogin = async () => {
    const { data } = await Axios.get(`${OTORISASI_URL}/api/v1/google/authUrl`, {
      params: {
        clientId: CLIENT_ID_GOOGLE,
        ...window.location.search ? { state: decodeURIComponent(window.location.search) } : {}
      }
    })
    window.location.href = data?.auth?.google
  }

  // const githubLogin = async () => {
  //   const { data } = await Axios.get(`${OTORISASI_URL}/api/v1/github/authUrl`, {
  //     params: {
  //       clientId: CLIENT_ID_GITHUB,
  //       ...window.location.search ? { state: decodeURIComponent(window.location.search) } : {}
  //     }
  //   })
  //   window.location.href = data?.auth?.github
  // }

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 8, offset: 8 }} md={{ span: 12, offset: 6 }}>
          <Spin spinning={loading}>
            <LogoOtorisasi alt="logo" src="/product-logo/Logogram/Otorisasi.svg" />
            <Card>
              <Form onFinish={login}>
                <H600>Log in with Your Otorisasi Account</H600>
                {hideAdditionalLoginOption ? <br /> : <p style={{ textAlign: 'center', marginTop: '8px', color: '#8993A4' }}>I don't have an account. <Link to="/signup" style={{ color: '#1256DF' }}>Sign me up!</Link></p>}
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Username cannot be blank' }]}>
                  <Input placeholder="Your username" prefix={<UserOutlined />} value={username} onChange={e => setUsername(e.target.value)} />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Password cannot be blank' }]}>
                  <Input.Password placeholder="Your password" prefix={<LockOutlined />} value={password} onChange={e => setPassword(e.target.value)} />
                </Form.Item>

                <Form.Item>
                  <Button style={{ float: 'right', background: '#1256DF' }} type="primary" htmlType="submit">Login</Button>
                  <p style={{ paddingTop: '5px' }}><Link to="/forgotPassword" style={{ color: '#1256DF' }}>Forgot Password?</Link></p>
                </Form.Item>

                <Recaptcha
                  ref={(ref: any) => setCaptcha(ref)}
                  sitekey={SITE_KEY_RECAPTCHA}
                  badge="bottomleft" />
              </Form>
              <Divider plain dashed style={{ color: '#BAC4CD' }}>or</Divider>
              <p style={{ marginBottom: '10px' }}>
                <Button icon={<img src="/google-icon.svg" alt="google" width="14px" />} block style={{ display: 'grid',gridTemplateColumns: 'max-content auto', alignItems: 'center' }} onClick={googleLogin}>Sign in with Google</Button>
              </p>
              {/* <p style={{ marginBottom: '10px' }}>
                <Button icon={<GithubOutlined />} block style={{ display: 'grid',gridTemplateColumns: 'max-content auto', alignItems: 'center' }} onClick={githubLogin}>Sign in with GitHub</Button>
              </p> */}

            </Card>
          </Spin>
          <br />
        </Col>
      </Row>
      <Products />
    </Layout.Content>
  )
}

export default Login

const LogoOtorisasi = styled.img`
  width: 63px;
  max-width: 100%;
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);
`
const H600 = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #5E6C84;
  text-align: center;
  margin-top: 20px;
`