import { SettingOutlined, WarningOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Col, Form, Input, Layout, message, Modal, Row, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OTORISASI_URL } from '../../../helpers/Constant'

const CreateApp: React.FC = () => {
  const history = useHistory()
  const [form] = useForm()
  const [client, setClient] = useState<any>()
  const [showModal, setShowModal] = useState<any>()

  useEffect(() => {
    Axios.get(`${OTORISASI_URL}/api/v1/user/me`, { withCredentials: true })
    .catch(() => history.push('/'))
  }, [history])

  const createClient = async () => {
    try {
      const { data } = await Axios.post(`${OTORISASI_URL}/api/v1/client`, { client: { ...client, scopes: ['all'] } }, { withCredentials: true })
      setClient(data.client)
      setShowModal(true)
      return message.success('Your app created successfully')
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error')
    }
  }

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 10, offset: 7 }} md={{ span: 18, offset: 3 }}>
          <Card title="Create App">
            <Form onFinish={createClient} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name cannot be blank' }]}>
                <Input value={client?.name} onChange={e => setClient({ ...client, name: e.target.value })} />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Description cannot be blank' }]}>
                <Input.TextArea value={client?.description} onChange={e => setClient({ ...client, description: e.target.value })} />
              </Form.Item>

              <Form.Item
                name="redirectUrl"
                label="Redirect URL"
                rules={[{ required: true, message: 'Redirect URL cannot be blank' }, { type: 'url', message: 'Redirect URL is not a valid URL' }]}>
                <Input value={client?.redirectUrl} onChange={e => setClient({ ...client, redirectUrl: e.target.value })} />
              </Form.Item>

              <Form.Item wrapperCol={{ sm: { span: 18, offset: 6 } }}>
                { client?.iconUrl ? <Avatar size="large" shape="square" src={client?.iconUrl} /> : <Avatar size="large" shape="square" icon={<SettingOutlined />} /> }
              </Form.Item>

              <Form.Item
                name="iconUrl"
                label="Icon URL"
                rules={[{ type: 'url', message: 'Icon URL is not a valid URL' }]}>
                <Input type="url" value={client?.iconUrl} onChange={e => setClient({ ...client, iconUrl: e.target.value })} />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }}>
                <Button style={{ float: 'right' }} type="primary" htmlType="submit">Create</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal
        title={client?.name}
        onOk={() => history.push('/apps')}
        onCancel={() => history.push('/apps')}
        destroyOnClose={true}
        visible={showModal}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          {/* <Form.Item
            label="Installation ID">
            <Input value={client?.installationId} contentEditable={false} />
          </Form.Item> */}
          <Form.Item
            label="Client ID">
            <Input value={client?.clientId} contentEditable={false} />
          </Form.Item>
          <Form.Item
            label="Client Secret">
            <Input value={client?.clientSecret} contentEditable={false} />
          </Form.Item>
        </Form>
        <Typography.Paragraph type="secondary" style={{ textAlign: 'right' }}>
          Save these credentials in your app environment securely <WarningOutlined />
        </Typography.Paragraph>
      </Modal>
    </Layout.Content>
  )
}

export default CreateApp