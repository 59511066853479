import { SettingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Layout, List, message, Row } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import Axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { OTORISASI_URL } from '../../helpers/Constant'

const Apps: React.FC = () => {
  const history = useHistory()
  const [user, setUser] = useState<any>()
  const [clients, setClients] = useState<any[]>()

  const _findClients = useCallback((user: any) => {
    Axios.get(`${OTORISASI_URL}/api/v1/clients`, { withCredentials: true, params: { createdBy: user?.id, orderBy: '"name":asc', isNeedCodeVerification: 'true' } })
    .then(({ data }) => setClients(data.clients))
    .catch(error => message.error(error?.response?.data?.error || 'Something error'))
  }, [])

  useEffect(() => {
    if (user === undefined) {
      Axios.get(`${OTORISASI_URL}/api/v1/user/me`, { withCredentials: true })
      .then(({ data }) => {
        setUser(data?.user)
        _findClients(data.user)
      })
      .catch(() => history.replace('/login'))
    }
  }, [history, user, _findClients])

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 10, offset: 7 }} md={{ span: 18, offset: 3 }}>
          <p style={{ textAlign: 'right' }}>
            Need help? <Link to="/docs">Read the docs</Link>
          </p>
          <Card title="My OAuth Apps" extra={<Link to="/apps/new">Create</Link>}>
          <List
            size="large"
            dataSource={clients}
            renderItem={item => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={<Link to={`/apps/${item.id}`}><Button style={{ padding: 0, height: 'auto' }} type="link">{item.name}</Button></Link>}
                  description={item.clientId}
                  avatar={item.iconUrl ? <Avatar size="large" shape="square" src={item.iconUrl} /> : <Avatar size="large" shape="square" icon={<SettingOutlined />} />}
                />
              </List.Item>
            )}
          />
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default Apps